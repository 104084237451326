<template>
  <div class="dp-summary">
    <div class="summary-text">
      <slot></slot>

      <!-- <div class="download-button" size="large" type="primary">
        <a :href="downloadLink">
          立即下载
        </a>
      </div> -->

      <el-row class="summary-link">
        <div class="list-btn" @click="share">分享</div>
        <div class="list-btn" @click="toDownload">应用下载</div>
        <div class="list-btn">讨论</div>
      </el-row>
    </div>
    <div
      class="summary-image"
      :style="{ maxWidth: showAllImg ? '100%' : '50%' }"
    >
      <div
        class="fixed-image"
        :style="{ width: showAllImg ? '100vw' : '50vw' }"
      >
        <div class="fixed-btn" @click="changePreview">
          <el-icon size="50px">
            <ArrowLeftBold v-if="!showAllImg" />
            <ArrowRightBold v-else />
          </el-icon>
        </div>
        <img :src="pewviewImg" alt="" srcset="" />
        <!-- <el-image :src="pewviewImg"></el-image> -->
      </div>

      <!-- <el-carousel :interval="200000" type="card">
        <el-carousel-item class="carousel-img" v-for="item in showImages" :key="item">
          <h3 justify="center">{{ item.alt }}</h3>
          <el-image :src="item.src"></el-image>
        </el-carousel-item>
      </el-carousel> -->
    </div>
    <input style="display: none" type="text" id="copytext" :value="shareText" />
    <DownloadAlret ref="refDownloadAlret" />
  </div>
</template>

<script>
import pewviewImg from "@/assets/app-preview/previews.png";
import DownloadAlret from "@/components/DownloadAlret.vue";

export default {
  components: {
    DownloadAlret,
  },
  props: {
    downloadLink: { type: String },
    link: { type: Array },
    showImages: { type: Object },
  },
  data() {
    return {
      pewviewImg,
      shareText:
        "青乌老黄历 |  | 日历 - 八字 - 宜忌 - 卜卦 - 罗盘 http://192.168.1.4:8080/",
      showAllImg: false,
      // 是否PC端
      isPc: true,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toDownload() {
      this.$refs.refDownloadAlret.open();
    },
    share() {
      this.copyText();
    },
    copyText() {
      const copyText = document.getElementById("copytext");
      if (!navigator.clipboard?.writeText) return alert("复制失败: ");
      navigator.clipboard
        .writeText(copyText.value)
        .then(function () {
          alert("已复制到剪贴板: " + copyText.value);
        })
        .catch(function (error) {
          alert("复制失败: " + error);
        });
    },
    async changePreview() {
      // 滑动屏幕到右侧
      if (!this.showAllImg) {
        this.showAllImg = !this.showAllImg;
        await this.$nextTick();

        setTimeout(() => {
          window.scrollTo({
            left: document.body.scrollWidth,
            behavior: "smooth",
          });
        }, 500);
      } else {
        window.scrollTo({
          left: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          this.showAllImg = !this.showAllImg;
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* .el-carousel__item .is-active .is-in-stage .el-carousel__item--card .carousel-img {
  position: fixed;
} */

.download-button {
  font-size: 1.1em;
  /* font-weight: bold; */
}

.dp-summary {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  text-decoration: dashed;
}

.download-button a {
  text-decoration: dashed;
  color: aliceblue;
}

/* 走马灯外部 */
.dp-summary .el-carousel__container {
  height: 60vh;
}

/* 走马灯内部图片 */
.dp-summary .el-image__inner {
  /* width: 32vh; */
}

.dp-summary .el-carousel__mask {
  background-color: rgba(129, 129, 129, 0.801);
}

.el-carousel__container {
  background-color: rgba(65, 65, 65, 0.616);
  border-bottom: 2px solid rgb(224, 224, 224);
  /* box-shadow: 1px 1px 10px aqua; */
  background-image: url("@/assets/xianhe.png");
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: right top -30%;
}

.summary-text {
  width: 60%;
  flex: 1;
}

.summary-image {
  /* height: 600px; */
  /* margin: 5%; */
  min-width: 40%;
  /* background-color: rgba(73, 73, 73, 0.616); */
  /* border-bottom: 2px solid rgb(206, 206, 206); */
  max-width: 50vw;
  flex: auto;
  transition: 0.5s;

  .fixed-image {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 50vw;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: 0.5s;

    > img {
      position: absolute;
      /* height: 100%; */
      /* width: 100%; */
      left: 50px;
      top: 0;
    }

    .fixed-btn {
      position: sticky;
      left: -50px;
      top: 50%;
      // background-color: red;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgb(172, 172, 172);
      // 左右移动动画
      animation: move 3s linear;
      // 鼠标手指
      cursor: pointer;
    }
  }
}

// 左右移动动画
@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

.summary-text {
  padding: 1em;
  text-align: left;
  font-size: 1.2em;
}

.summary-link {
  font-family: ltqcc;
  margin: 1.5em;
  display: flex;
  justify-content: center;
  font-size: 25px;
  /* align-items: center; */
}

.summary-link .list-btn {
  user-select: none;
  width: 1.5em;
  background-color: rgb(255, 255, 255);
  color: rgb(22 176 177);
  margin: 2px 6px;
  padding: 30px 6px;
  text-justify: inter-word;
  text-align: center;
  position: relative;
  border: 6px double rgb(22 176 177);
  top: 0;
  transition: 0.5s;
  cursor: pointer;
}

.summary-link .list-btn:hover {
  top: -10px;
  box-shadow: 1px -1px 10px rgba(0, 0, 0, 0.699);
}

.summary-goto-help {
  position: relative;
  height: auto;
  color: silver;
  font-size: 5em;
  top: 0.3em;
}

@media screen and (max-width: 600px) {
  .dp-summary {
    /* width: 100%; */
  }

  .summary-image {
    max-width: 100% !important;
    height: 50vh;
    height: max-content;
    position: relative;
    margin: auto;
    .fixed-image {
      // position: absolute;
      // top: 0;
      // bottom: 0;
      position: relative;
      // height: 50vh;
      width: 500% !important;
      overflow-x: scroll;
      overflow-y: hidden;
      // 自动滑动动画
      animation: autoMove 10s linear infinite;
      //  background-image: url("@/assets/app-preview/previews.png");
    }
    .fixed-btn {
      display: none !important;
    }
  }

  .summary-text {
    width: 100%;
    flex: auto;
  }

  /* 走马灯外部 */
  /* .dp-summary .el-carousel__container {
    height: 300px;
  }

  .dp-summary .el-image__inner {
    width: 100%;
  } */

  // 自动滑动动画 autoMove
  @keyframes autoMove {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0);
    }
  }
}
</style>
