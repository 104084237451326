<template>
    <footer class="footer-icp">
        <!-- <p class="strong">☯ 青乌老黄历</p> -->
        <!-- <span> | </span> -->
        <!-- <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010502000772"> -->
            <!-- <img src="https://zzerx.cn/images/beian.png" style="float:left;box-shadow: none;"> -->
            <!-- <p>
                <span>桂公网安备</span>
                <span class="_num strong">{{ icp }}</span>
                <span>号</span>
            </p> -->
        <!-- </a> -->
    </footer>
</template>

<script>
export default {
    props: {
        owner: { type: String },
        icp: { type: String }
    },
    data() {
        return {

        }
    }
}
</script>

<style>
.footer-icp {
    position: fixed;
    bottom: 20px;
    font-family: ltqcc;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    font-size: larger;
    /* font-size: ; */
}

.footer-icp p {
    float: left;
    height: 20px;
    line-height: 20px;
    margin: 0px 0px 0px 5px;
    color: #939393;
}

.footer-icp span {
    margin: 0em 5px;
}

.footer-icp ._num{
    color: rgb(0, 180, 180);
}

.strong{
    font-weight: bold;
}

@media screen and (max-width: 600px) {
.footer-icp{
    /* display: flex; */
    font-size: small;
    position: sticky;
    /* flex-direction: column; */
}

}
</style>