<template>
  <div class="characters-bg">
    <div class="characters-bg-view" :style="getRandomPosition()" v-for="(item, index) in characters" :key="index">
      <p v-for="(t, ti) in item" :key="ti">{{ t }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      characters: [
        [
          "道可道，非常道；名可名，非常名。",
          "无名天地之始，有名万物之母。",
          "故常无，欲以观其妙；常有，欲以观其徼。",
          "此两者同出而异名，同谓之玄，玄之又玄，众妙之门。",
        ],
        [
          "天下皆知美之为美，斯恶已。",
          "皆知善之为善，斯不善已。",
          "故有无相生，难易相成，长短相形，高下相倾，音声相和，前后相随，恒也。",
          "是以圣人处无为之事，行不言之教，万物作焉而不辞，生而不有，为而不恃，功成而弗居。",
          "夫唯弗居，是以不去。"
        ],
        [
          "天地不仁，以万物为刍狗；圣人不仁，以百姓为刍狗。",
          "天地之间，其犹橐籥乎, 虚而不屈，动而愈出。",
          "多言数穷，不如守中。"
        ],
        [
          "有物混成，先天地生，寂兮寥兮，独立而不改，周行而不殆，可以为天地母。",
          "吾不知其名，字之曰道，强为之名曰大。",
          "大曰逝，逝曰远，远曰反。",
          "故道大，天大，地大，人亦大。",
          "域中有四大，而人居其一焉。",
          "人法地，地法天，天法道，道法自然。",
        ],
        [
          "反者道之动，弱者道之用。",
          "天下万物生于有，有生于无。"
        ],
        [
          "道生一，一生二，二生三，三生万物。",
          "万物负阴而抱阳，冲气以为和。",
          "人之所恶，唯孤、寡、不穀，而王公以为称。",
          "故物或损之而益，或益之而损。",
          "人之所教，我亦教之。",
          "强梁者不得其死，吾将以为教父。"
        ],
        ["天地与我并生，而万物与我为一。"],
        ["有实而无乎处者，宇也；有长而无本剽者，宙也。"],
        // [
        //   "企者不立，跨者不行，自见者不明，自是者不彰，自伐者无功，自矜者不长。",
        //   "其在道也，曰余食赘行，物或恶之。",
        //   "故有道者不处。"
        // ],
        // [
        //   "知人者智，自知者明。",
        //   "胜人者有力，自胜者强。",
        //   "知足者富，强行者有志。",
        //   "不失其所者久，死而不亡者寿。"
        // ], [
        //   "人生天地之间，若白驹之过隙，忽然而已。",
        //   "注然勃然，莫不出焉；油然寥然，莫不入焉。",
        //   "已化而生，又化而死。",
        //   "生物哀之，人类悲之。",
        //   "解其天韬，堕其天帙。"
        // ]
      ]
    };
  },
  mounted() {
  },
  methods: {
    getRandomPosition() {
      // 获取网页的宽度和高度
      const pageWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      // 定义 div 大小
      const divWidth = 8 * pageWidth / 100;  // 8vw
      const divHeight = 30 * pageHeight / 100;  // 30vh

      // 定义位置间隔
      const minInterval = 150;  // 最小间隔
      let left = Math.random() * (pageWidth - divWidth);
      let top = Math.random() * (pageHeight - divHeight);
      // let opacity = Math.random() * 0.4 + 0.1;


      // 检查位置间隔
      if (left % minInterval < divWidth) {
        left += minInterval;
      } else {
        left -= minInterval;
      }
      if (top % minInterval < divHeight) {
        top += minInterval;
      } else {
        top -= minInterval;
      }

      // 生成 CSS 样式

      return {
        // left: `${left}px`,
        // top: `${top}px`,
        // opacity: `${opacity}`
      };
    }
  }
};
</script>
<style lang="scss" scoped>
$font-color: rgb(0, 54, 54);
$bg-color: rgb(233, 255, 255);
.characters-bg{
  z-index: -1;
  display: flex;
  position: fixed;
  top: 10vh;
}
.characters-bg-view {
  /* position: fixed; */
  font-family: ltqcc;
  text-align: start;
  writing-mode: vertical-rl;
  padding: 4px;
  color: rgb(134, 134, 134);
  font-size: 25px;
  letter-spacing: 1px;
  user-select: none;
  opacity: 0.2;
  /* transition: 1s; */
  // animation: light-bgtext 10s ease-in-out infinite;
}
@keyframes light-bgtext {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.3;
    text-shadow: 1px 1px 6px white;
  }
  100% {
    opacity: 0.1;
  }
}
</style>
